import Link from 'next/link';
import styles from './EmailSignupBanner.module.scss';
import { useForm } from '@mantine/form';
import { Button, Group, Stack, TextInput } from '@mantine/core';
import { useState } from 'react';
import { Text } from '@mantine/core';
import { logSubscribeToNewsletter, setBrowserEmail } from '../lib/logger';
import useAuth from '../lib/useAuth';
import axios from 'axios';
import { Price } from './Localization';

type formValues =
{
  email:string;
}

export default function EmailSignupBanner(props: {alwaysShow?: boolean, minimal?:boolean, buttonText?:string})
{
    let [submitting, setSubmitting] = useState(false);
    let [submitted, setSubmitted] = useState(false);

    let {loggedIn} = useAuth(); 

    const form = useForm<formValues>({
        initialValues: {
          email: '',
        }
    });

    async function submitHandler(data : formValues)
    {
    
      setSubmitting(true);
      await axios.post('/n/api/subscribe',
      JSON.stringify(data),
      {
        headers:{'Content-Type': 'application/json'},
        withCredentials: true             
    });

    /*

        await fetch('/n/api/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        credentials:"include"
      });*/

      logSubscribeToNewsletter();
      await setBrowserEmail(data.email);

      setSubmitting(false);
      setSubmitted(true);
    }

    if (loggedIn && !props.alwaysShow)
    {
      return <></>
    }
    
    if (submitted)
    {
      return(
        <div className={styles.container}>
          <Text fw={700}>Thank you for subscribing! We have emailed you a link - please check your spam folder if it hasn&rsquo;t arrived in 5 minutes.</Text>
          </div>
      );

    }
    else
    {
    return(
        <div className={styles.container}>

        <form onSubmit={form.onSubmit(async (values) => await submitHandler(values))}>
        <Stack>
        {!props.minimal && <div>Subscribe to our mailing list and we&rsquo;ll keep you informed about upcoming online sessions, workshops and events. Plus we&rsquo;ll give you access to <b>SEVEN</b> of our previous sessions, and a voucher worth <Price gbp={5}/> for a future session, all for <b>FREE</b></div>}
<Group align={'flex-end'}>
          <TextInput
            type='email'
          
            label="Email"
            placeholder="hello@example.com"
            value={form.values.email}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => form.setFieldValue('email', event.currentTarget.value)}
            error={form.errors.email && 'Invalid email/username'}
          />
        <Button disabled={submitting} type="submit">{props.buttonText??"Subscribe"}</Button>

</Group>
        </Stack>
 

<div className={styles.privacyPolicy}>
We won&rsquo;t sell or share your data and you can unsubscribe at any time. You can see our privacy policy <Link href='/privacy-policy'>here</Link>
</div>
</form>
</div>
    );
}
}