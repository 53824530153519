import { GetServerSideProps } from 'next'
import Layout from '../components/Layout'
import styles from './index.module.scss'
import {soliloquyMainSlider} from '../lib/data'
import {SliderData} from '../lib/definitions'
import { Carousel } from '@mantine/carousel';
import Autoplay from 'embla-carousel-autoplay'

import { useRef } from 'react';
import Link from 'next/link'

import classesImage from '../images/home-page/classes_500.jpg'
import subscriptionImage from '../images/home-page/library_500.jpg'
import onlineImage from '../images/home-page/portrait_500.jpg'
import videosImage from '../images/home-page/videos_500.jpg'
import imagesImage from '../images/home-page/becca_500.jpg'
import Image, { StaticImageData } from 'next/image'
import EmailSignupBanner from '../components/EmailSignupBanner'
import { Space } from '@mantine/core'
import Head from 'next/head'
import { MenuId } from '../components/Header'
import { AspectBox } from '../components/AspectBox'

interface PageProps{
    sliders : SliderData []
}

interface SliderProps{
    sliders : SliderData []
}

interface PaneProps {
    title: string,
    description:string,
    link:string,
    img:StaticImageData,
    priority?: boolean
}

export default function HomePage(props: PageProps) {
    
        return (
            <Layout menuId={MenuId.home}>
                <Head>
                    <title>Raw Umber Studios</title>
                    <meta name="description" content="Art courses in Stroud, UK and online sessions featuring artists from around the world. Plus a large library of videos and image references."></meta>
                </Head>
                <div className = {styles.mainContent}>
                <h1>Raw Umber Studios</h1>
                <p className = {styles.topLine}>We’re a non-profit art school and studio based in Stroud, UK. We run short, focussed <Link href='/new-events'>workshops</Link> teaching the skills needed to create representational art. We also run regular <Link href='/upcoming-online-sessions'>online sessions</Link> and have <Link href='/image-search'>searchable library</Link> of some 2,000 high quality reference images.</p>
                <MainSlider sliders={props.sliders}/>
                </div>
                <div className={styles.paneCollection}>
                    <Pane key='online' title="Online Sessions" description="Online portrait and figure drawing sessions twice a week. They’re a great way to carve out time to practice your art." link="/upcoming-online-sessions/" img={onlineImage} priority/>
                    <Pane key='workshops' title="Workshops" description="Regular courses at our studios in Stroud, UK, featuring artists from all over the globe." link="/new-events/"  img={classesImage}/>
                    <Pane key='image-library'  title="Image Library" description="Some two thousand images, categorised and searchable." link="/image-search/"  img={imagesImage}/>
                    <Pane key='library' title="Video Library" description="A searchable library of video tutorials, including charcoal, pencil, oils, watercolours and more." link="/new-video-search/"  img={videosImage} />
                    <Pane key= 'subscriptions' title="Subscription" description="Regular online figure and portrait sessions, plus an extensive online library of art tutorials." link="/online/"  img={subscriptionImage}/>
                </div>

                <Space h="lg" />
                <EmailSignupBanner/>
            </Layout>
        )
    }

    function Pane(props: PaneProps)
    {
            return <div className={styles.pane}>
                <AspectBox width={16} height={9}>

                        <div className={styles.paneImage}>
                        <Link href={props.link}>
                            <div className={styles.imageWrapper} >
                            <Image fill sizes='500px' src={props.img} alt={props.title} priority={props.priority}></Image>
                            </div>
                        </Link>
                        <div className={styles.paneText}>
                        <h2>
                            <Link href={props.link}>{props.title}</Link> 
                        </h2>
                        </div>
                    </div>
                    </AspectBox>

                    <div className={styles.paneBlock}>{props.description} <Link href={props.link}>Learn more ...</Link></div>
                    </div>

    }
    function MainSlider(props : SliderProps)
    {
        const autoplay = useRef(Autoplay({ delay: 3000 }));
        const imageRegEx = new RegExp(/\/wp-content\/uploads\/(.*)/);
        
        let i = 0;
  return (
    <div className={styles.carousel}>
        <AspectBox  width={1040} height={360}>
    <Carousel
      sx={{ maxWidth: 1040 }}
      mx="auto"
      plugins={[autoplay.current]}
      loop = {true}
    //withIndicators
      
    
    >
        {
        props.sliders.map((x) =>
        {
            let link = x.link;
            let c = imageRegEx.exec(x.src);

            if (c)
            {
            let src = c[1];

            if (!link)
            {
                link="/";
            }
            else
            {
                if (!link.startsWith("/"))
                {
                    link = new URL(link).pathname;
                }
            }
            return(
        <Carousel.Slide  key={i++}>
            <AspectBox height={240} width={260}>

            {<a href={link}><img width="100%"
            src={`/n/api/wpimage?slug=${encodeURIComponent(src)}&w=1040`}
            alt={x.title}
            
          /></a>}
          </AspectBox></Carousel.Slide>)
        }
        }
        )}
   
    </Carousel>
    </AspectBox>
    </div>
  );

    }

    export const getServerSideProps : GetServerSideProps = async (context)=>
{

    let sliders = await soliloquyMainSlider();

    return {
        props: {
            sliders
        }
    }
}


