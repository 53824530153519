import { CSSProperties, ReactNode } from "react"

export function AspectBox({width, height, children, absolute} : {width:number, height:number, children:ReactNode, absolute?: boolean})
{
    let styles : CSSProperties = {paddingTop: (100 * height / width) + '%'};

    if (absolute)
    {
        styles = {position:'absolute', left:'0px', top:'0px', width:'100%', paddingTop: (100 * height / width) + '%'};
    }
   // if (!fixedVertical)
    {
    return(
        <div style= {styles} className='rus-aspect-ratio-box'>
            <div className='rus-aspect-ratio-box-inside'>
            {children}
            </div>
            </div>);
    }
    /*
    else
    {
        return(
            <div styl={{}}
            <div style={{position:"relative"}}>
                <svg xmlns="http://www.w3.org/2000/svg"
      height={width}
      width={height}
      style={{width:"auto", height:"100%", borderStyle:'solid', position}}
    />
               </div>
        );

    
    }*/
}